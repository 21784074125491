import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer style={{ backgroundColor: "#1a1a1a", color: "#ffffff", padding: "20px", textAlign: "center", position: "relative" }}>
      
      
      <p style={{ margin: "10px 0 0 0" }}>© Copyright {currentYear}. PureForm Labs</p>
      <p style={{ margin: "10px 0 0 0" }}>
        Designed and developed by{" "}
        <a
          href="https://www.codenovalab.com"
          target="_blank"
          
          rel="noopener noreferrer"
          style={{ color: "#4caf50", textDecoration: "none" }}
        >
          CodeNovaLabs
        </a>
      </p>
    </footer>
  );
};

export default Footer;
