const productList = [
    {
      mainCategory: "Syrups",
      subCategory: "Digestive Health",
      segment: "Nutraceuticals",
      dosageForms: "Syrup",
      composition: "L-Carnosine in a Sorbitol Base.",
    },
    {
      mainCategory: "Tablets",
      subCategory: "Women's Health",
      segment: "Nutraceuticals",
      dosageForms: "Tablet",
      composition:
        "Myoinositol 550 MG, D-Chiroinositol 13.8 MG, N-Acetyl Cysteine 300 MG, Vitamin D3 600 IU, Chromium Picolinate 100 MCG, Vitex Agnus 50 MG, Fenugreek Extract 200MG, Berberine 50 MG.",
    },
    {
      mainCategory: "Tablets",
      subCategory: "Energy Boosters",
      segment: "Nutraceuticals",
      dosageForms: "Tablet",
      composition:
        "Astaxanthin 8MG, L-Arginine 500 MG, Pyridoxal-5 Phosphate 0.5 MG, Folic Acid 5MG, Methylcobalamin 750 MCG, Zinc 22.5 MG, Piperine 5 MG, Lycopene 20 MG, Sodium Selenate 100 MCG, Iron 25 MG.",
    },
    {
      mainCategory: "Capsules",
      subCategory: "Liver Health",
      segment: "Nutraceuticals",
      dosageForms: "Capsule",
      composition:
        "Lycopene, L-Ornithine L-Aspartate, L-Carnitine L-Tartrate, Silymarin, Glutathione, Curcumin, Zinc, Folic Acid.",
    },
    {
      mainCategory: "Capsules",
      subCategory: "Heart Health",
      segment: "Nutraceuticals",
      dosageForms: "Capsule",
      composition: "Coenzyme Q10, DHA, EPA, L-Carnitine, Lycopene, Zinc Sulphate, L-Arginine.",
    },
    {
      mainCategory: "Syrups",
      subCategory: "Multivitamin",
      segment: "Nutraceuticals",
      dosageForms: "Syrup",
      composition: "Lycopene, multivitamin syrup.",
    },
    {
      mainCategory: "Capsules",
      subCategory: "Bone Health",
      segment: "Nutraceuticals",
      dosageForms: "Capsule",
      composition:
        "Calcium Citrate 500mg, Magnesium Sulphate 250 mg, L-Carnitine 500mg, Potassium 75 mg, Vitamin D3 1000 IU, Vitamin B5 6mg.",
    },
    {
      mainCategory: "Tablets",
      subCategory: "Reproductive Health",
      segment: "Nutraceuticals",
      dosageForms: "Tablet",
      composition:
        "Myo/Chiro Inositol, Cinnamon, Berberine, Fenugreek, Chamomile, Grape Seed Extract.",
    },
    {
      mainCategory: "Jars",
      subCategory: "Protein Supplements",
      segment: "Nutraceuticals",
      dosageForms: "Jar",
      composition: "Protein Granules.",
    },
    {
      mainCategory: "Syrups",
      subCategory: "Liver Health",
      segment: "Nutraceuticals",
      dosageForms: "Syrup",
      composition:
        "Silymarin 140 mg, L-Ornithine L-Aspartate, Thiamine HCL, Riboflavin, Pyridoxine, Niacinamide, Vitamin B12.",
    },
    {
      mainCategory: "Tablets",
      subCategory: "General Health",
      segment: "Nutraceuticals",
      dosageForms: "Tablet",
      composition:
        "Pyridoxine HCL, Cyanocobalamin, Ginseng Powder, Nicotinamide, Calcium Pantothenate, Folic Acid.",
    },
    {
      mainCategory: "Tablets",
      subCategory: "Multivitamins",
      segment: "Nutraceuticals",
      dosageForms: "Tablet",
      composition: "Multivitamin and Multiminerals Tablet.",
    },
    {
      mainCategory: "Jars",
      subCategory: "Protein Powder",
      segment: "Nutraceuticals",
      dosageForms: "Jar",
      composition: "Protein Powder with Multiminerals.",
    },
    {
      mainCategory: "Capsules",
      subCategory: "Bone Health",
      segment: "Nutraceuticals",
      dosageForms: "Capsule",
      composition:
        "Calcium Citrate Malate, Vitamin K27, Vitamin D3, Methylcobalamin, Zinc Oxide.",
    },
    {
      mainCategory: "Syrups",
      subCategory: "Digestive Health",
      segment: "Nutraceuticals",
      dosageForms: "Syrup",
      composition: "Diastase, Pepsin.",
    },
  ];
  
  export default productList;
  