import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import LogoFull from "../../assets/logo-bgremoved.png";
import DownloadBoucher from "../../assets/Downloads/folder2.pdf";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navRef = useRef();

  const isProductsPage = location.pathname === "/products";

  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setMenuOpen(false);
    }
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const closeMenu = () => setMenuOpen(false);

  // Close menu if clicking outside the nav
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        closeMenu();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav
      ref={navRef}
      className="relative flex justify-between items-center text-black py-4 px-6 md:px-16 bg-white drop-shadow-md"
    >
      <Link to="/" className="mr-auto">
        <img
          className="w-40 hover:scale-105 transition-all"
          src={LogoFull}
          alt="Pure Form Labs"
        />
      </Link>

      {!isProductsPage && (
        <>
          <div className="hidden xl:flex items-center gap-6">
            <ul className="flex items-center gap-6 font-medium text-sm">
              <li className="p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                <button onClick={() => handleScroll("about-us")}>About Us</button>
              </li>
              <li className="p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                <Link to="/products">Products</Link>
              </li>
              <li className="p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                <button onClick={() => handleScroll("testimonials")}>
                  Testimonials
                </button>
              </li>
              <li className="p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                <button onClick={() => handleScroll("manufacturing-facility")}>
                  Manufacturing Facility
                </button>
              </li>
              <li className="p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                <a href={DownloadBoucher} download="PureFormLabs_Brochure.pdf">
                  Download Brochure
                </a>
              </li>
            </ul>
            <button
              onClick={() => handleScroll("contact-us")}
              className="text-white bg-gradient-to-br from-[#A9CF46] to-[#328855] hover:bg-gradient-to-bl focus:ring-2 focus:outline-none focus:ring-green-200 font-medium rounded-lg text-sm px-4 py-2 text-center"
            >
              Contact Us
            </button>
          </div>

          <FontAwesomeIcon
            icon={faBars}
            className="xl:hidden block text-3xl cursor-pointer text-gray-700"
            onClick={toggleMenu}
          />

          {menuOpen && (
            <>
              <ul className="absolute top-full left-0 w-full bg-white shadow-md flex flex-col items-center gap-4 py-4 font-medium text-sm z-50">
                <li className="w-full text-center p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                  <button onClick={() => handleScroll("about-us")}>About Us</button>
                </li>
                <li className="w-full text-center p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                  <Link to="/products" onClick={closeMenu}>
                    Products
                  </Link>
                </li>
                <li className="w-full text-center p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                  <button onClick={() => handleScroll("testimonials")}>
                    Testimonials
                  </button>
                </li>
                <li className="w-full text-center p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                  <button onClick={() => handleScroll("manufacturing-facility")}>
                    Manufacturing Facility
                  </button>
                </li>
                <li className="w-full text-center p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                  <a
                    href={DownloadBoucher}
                    download="PureFormLabs_Brochure.pdf"
                    onClick={closeMenu}
                  >
                    Download Brochure
                  </a>
                </li>
                <li className="w-full text-center p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
                  <button onClick={() => handleScroll("contact-us")}>
                    Contact Us
                  </button>
                </li>
              </ul>
            </>
          )}
        </>
      )}

      {isProductsPage && (
        <div className="hidden xl:flex items-center gap-6">
          <ul className="flex items-center gap-6 font-medium text-sm">
            <li className="p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
              <Link to="/products">Products</Link>
            </li>
            <li className="p-2 hover:bg-[#65A64E] hover:text-white rounded-md transition-all cursor-pointer">
              <a href={DownloadBoucher} download="PureFormLabs_Brochure.pdf">
                Download Brochure
              </a>
            </li>
          </ul>
        </div>
      )}

      {isProductsPage && (
        <Link
          to="/"
          className="text-white bg-gradient-to-br from-[#A9CF46] to-[#328855] hover:bg-gradient-to-bl focus:ring-2 focus:outline-none focus:ring-green-200 font-medium rounded-lg text-sm px-4 py-2 text-center"
        >
          Back to Home
        </Link>
      )}
    </nav>
  );
};

export default NavBar;
