import React from "react";

const CallToAction = () => {
  const phoneNumber = "+916239380353"; // Replace with your desired number

  const openDialer = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className="flex items-center justify-center bg-[#65A64E] p-6 text-white">
      <div className="w-[80vw] sm:w-7/10 flex flex-col sm:flex-row items-center justify-between sm:space-x-6 mx-auto">
        {/* Left Section */}
        <div className="text-lg font-bold sm:w-7/10 text-center sm:text-left">
          Schedule an Appointment
        </div>
        {/* Right Section */}
        <button
          onClick={openDialer}
          className="bg-black text-white px-6 py-3 font-semibold rounded shadow hover:bg-gray-800 transition duration-300 mt-4 sm:mt-0"
        >
          BOOK CALL NOW
        </button>
      </div>
    </div>
  );
};

export default CallToAction;
