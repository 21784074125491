import React from 'react'
import Herosection from '../Components/HeroSection/Herosection'
import WhatWeServe from '../Components/WhatWeServe/WhatWeServe'
import CallToAction from '../Components/Others/CallToAction'
import AboutUs from '../Components/AboutUs/Aboutus'
import ManufacturingFacility from '../Components/Manufacturing/ManufacturingFacility'
import Testimonials from '../Components/Testimonials/Testimonials'
import ContactUs from '../Components/ContactUs/ContactUs'
import Licenses from '../Components/Certifications/Licenses'

const Home = () => {
  return (
    <div>
        <Herosection/>
        <Licenses/>
        <CallToAction/>
        <WhatWeServe/>
        <AboutUs/>
        <ManufacturingFacility/>
        <Testimonials/>
        <ContactUs/>
    </div>
  )
}

export default Home