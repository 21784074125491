import React from 'react';
import WhatsAPPIcon from "../../assets/serve/whatsapp.png"; // Import the WhatsApp icon image

const FloatingWhatsAppButton = () => {
  const phoneNumber = "+918240171076";
  const message = "Hello! I would like to learn more about your services.";

  return (
    <a
      href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-12 left-6 rounded-full shadow-lg p-4 transition-all"
      aria-label="Chat with us on WhatsApp"
      style={{ zIndex: 1000 }}
    >
      {/* Use the imported WhatsApp icon image */}
      <img
        src={WhatsAPPIcon}
        alt="WhatsApp Icon"
        className="w-8 h-8 md:w-12 md:h-12" // Small on mobile, larger on desktop
      />
    </a>
  );
};

export default FloatingWhatsAppButton;
