import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import validateForm from "./Validation"; // Import the validation function

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    contactType: "General",
  });

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Validate field on change
    const errorMessage = validateForm(name, value);
    setErrors({
      ...errors,
      [name]: errorMessage,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields before submitting
    const newErrors = {
      name: validateForm("name", formData.name),
      email: validateForm("email", formData.email),
      mobile: validateForm("mobile", formData.mobile),
      message: validateForm("message", formData.message),
    };

    // Check if any error exists
    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      setAlert({
        show: true,
        message: "Please fix the errors in the form.",
        type: "danger",
      });
      setTimeout(() => setAlert({ show: false, message: "", type: "" }), 3000);
      return;
    }

    try {
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID, // EmailJS Service ID
        process.env.REACT_APP_TEMPLATE_ID, // EmailJS Template ID
        {
          from_name: formData.name,
          from_email: formData.email,
          phone_number: formData.mobile,
          message: formData.message,
          query_type: formData.contactType,
        },
        process.env.REACT_APP_PUBLIC_KEY // EmailJS Public Key
      );

      setAlert({
        show: true,
        message: "Query submitted successfully!",
        type: "success",
      });

      // Clear form
      setFormData({
        name: "",
        email: "",
        mobile: "",
        message: "",
        contactType: "General",
      });
      setErrors({
        name: "",
        email: "",
        mobile: "",
        message: "",
      });
    } catch (error) {
      console.error("Failed to send message:", error);
      setAlert({
        show: true,
        message: "Failed to send message. Please try again later!",
        type: "danger",
      });
    }

    setTimeout(() => setAlert({ show: false, message: "", type: "" }), 3000);
  };

  return (
    <div
      className="flex flex-col items-center bg-gray-50 py-12 px-6 md:px-16"
      id="contact-us"
    >
      {/* Popup Notification */}
      {alert.show && (
        <div
          className={`fixed top-0 left-1/2 transform -translate-x-1/2 z-50 mt-4 p-4 rounded shadow-lg ${
            alert.type === "success"
              ? "bg-green-500 text-white"
              : "bg-red-500 text-white"
          }`}
        >
          {alert.message}
        </div>
      )}

      {/* Container */}
      <div className="w-full max-w-[1200px] bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex flex-col md:flex-row">
          {/* Left Section: Contact Us Form */}
          <div className="md:w-1/2 p-8">
            <h2 className="text-2xl md:text-3xl font-bold text-[#65A64E] mb-6">
              Contact Us
            </h2>

            <form onSubmit={handleSubmit} className="space-y-5">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#65A64E]"
                  required
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#65A64E]"
                  required
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="mobile"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Mobile Number
                </label>
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#65A64E]"
                  required
                />
                {errors.mobile && (
                  <p className="text-red-500 text-sm">{errors.mobile}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#65A64E]"
                  rows="4"
                  required
                ></textarea>
                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Type of Query
                </label>
                <select
                  name="contactType"
                  value={formData.contactType}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#65A64E]"
                >
                  <option value="General">General</option>
                  <option value="Support">Support</option>
                  <option value="Feedback">Feedback</option>
                </select>
              </div>
              <button
                type="submit"
                className="bg-[#65A64E] text-white px-4 py-2 text-sm font-medium rounded-md hover:bg-green-700 transition duration-300"
              >
                Submit
              </button>
            </form>
          </div>

          {/* Right Section: Address and Google Map */}
          <div className="md:w-1/2 bg-gray-100 p-8 flex flex-col justify-center">
            <h3 className="text-xl font-bold text-[#65A64E] mb-4">
              Our Contact Information
            </h3>

            {/* Phone */}
            <p className="text-sm text-gray-700 mb-2">
              <strong>Phone: </strong>
              <a
                href="tel:+918240171076"
                className="text-blue-500 hover:underline"
              >
                +918240171076
              </a>
            </p>

            {/* Email */}
            <p className="text-sm text-gray-700 mb-2">
              <strong>Email: </strong>
              <a
                href="mailto:pureformlabsindia@gmail.com"
                className="text-blue-500 hover:underline"
              >
                pureformlabsindia@gmail.com
              </a>
            </p>

            {/* Address */}
            <p className="text-sm text-gray-700">
              <strong>Address: </strong> B1-6, Fatuha Industrial Area, Patna, Bihar –
              803201, India
            </p>

            {/* Google Maps */}
            <div className="mt-4">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28804.747242379894!2d85.28188806732705!3d25.518603389664392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f29ed81dc9ddf1%3A0x2fb0fb1e08de9598!2sAdya%20Packaging%20pvt.ltd%2Cindustrial%20Area%2Cfatuha!5e0!3m2!1sen!2sin!4v1732808183969!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
