import React from 'react';
import FloatingWhatsAppButton from '../Others/FloatingWhatsAppButton';
import HeroImage from "../../assets/Hero.webp"
const Herosection = () => {
  return (
    <section className="bg-white min-h-screen md:min-h-[80vh] flex flex-col md:flex-row items-center px-6 md:px-16">
      {/* Text Section */}
      <div className="flex-1 text-center md:text-left flex flex-col justify-center">
        <h1 className="text-3xl md:text-5xl font-bold text-[#65A64E] mb-4">
          Welcome to Pure Form Labs
        </h1>
        
        
        <p className="text-gray-700 text-lg md:text-xl leading-relaxed">
          A first and only nutraceutical manufacturing unit located in the heart of Patna, Bihar.
          At Pure Form Labs, we specialize in producing a diverse range of high-quality dietary
          supplements and functional foods that cater to the health and wellness needs of our valued
          customers.
        </p>
      </div>

      {/* Image Section */}
      <div className="flex-1 mt-8 md:mt-0 flex justify-center md:justify-end">
        <img
          src={HeroImage} // Replace with your actual image path
          alt="Pure Form Labs"
          className="w-4/5 md:w-full h-auto rounded-md "
        />
      </div>

      {/* Floating WhatsApp Button */}
      <FloatingWhatsAppButton />
    </section>
  );
};

export default Herosection;
