import React, { useState, useEffect } from "react";
import FSSAI from "../../assets/logos/fssai.gif";
import WHOGMP from "../../assets/logos/who-gmp.png";
import ISO2018 from "../../assets/logos/iso220002018.webp";
import ISO2015 from "../../assets/logos/iso90002015.png";

import FSAAICertificate from "../../assets/Downloads/fassai.pdf";
import ISO2015Certificate from "../../assets/Downloads/iso2015.pdf";
import ISO2018Certificate from "../../assets/Downloads/iso2018.pdf";
import WHOGMPCertificate from "../../assets/Downloads/who-gmp.pdf";

const Licenses = () => {
  const licenses = [
    { id: 1, image: FSSAI, alt: "FSSAI License", pdf: FSAAICertificate },
    {
      id: 2,
      image: ISO2015,
      alt: "ISO Certified Company",
      pdf: ISO2015Certificate,
    },
    {
      id: 3,
      image: ISO2018,
      alt: "ISO Certified Food Safety",
      pdf: ISO2018Certificate,
    },
    { id: 4, image: WHOGMP, alt: "WHO-GMP", pdf: WHOGMPCertificate },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  useEffect(() => {
    const updateVisibleCount = () => {
      if (window.innerWidth < 640) {
        setVisibleCount(1);
      } else if (window.innerWidth < 1024) {
        setVisibleCount(2);
      } else {
        setVisibleCount(3);
      }
    };

    updateVisibleCount();
    window.addEventListener("resize", updateVisibleCount);
    return () => window.removeEventListener("resize", updateVisibleCount);
  }, []);

  const getVisibleLicenses = () => {
    const visibleItems = licenses.slice(
      currentIndex,
      currentIndex + visibleCount
    );
    return visibleItems.length < visibleCount
      ? [
          ...visibleItems,
          ...licenses.slice(0, visibleCount - visibleItems.length),
        ]
      : visibleItems;
  };

  const handlePrev = () => {
    setCurrentIndex((prev) =>
      prev === 0 ? licenses.length - visibleCount : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % licenses.length);
  };

  const openModal = (pdf) => {
    setSelectedPdf(pdf);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedPdf(null);
  };

  return (
    <section
      id="licenses"
      className="bg-gradient-to-br from-green-50 to-white py-16"
    >
      <div className="container mx-auto px-6 md:px-12">
        <div className="flex flex-col items-center md:hidden text-center mb-6">
          <p className="text-3xl text-[#65A64E] mb-4">OUR LICENSE</p>
        </div>

        <div className="flex flex-col md:flex-row md:items-center">
          <div className="hidden md:block md:w-1/3">
            <h2 className="text-4xl font-extrabold text-[#65A64E]">
              OUR LICENSE
            </h2>
          </div>

          <div className="md:w-2/3">
            <div className="relative flex justify-between items-center">
              <button
                onClick={handlePrev}
                className="absolute left-0 bg-white rounded-full shadow-lg border border-gray-200 p-3 hover:bg-gray-50 focus:outline-none z-10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-green-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>

              <div className="flex justify-center items-center overflow-hidden w-full space-x-4">
                {getVisibleLicenses().map((license) => (
                  <div
                    key={license.id}
                    className="flex justify-center items-center w-40 h-40 md:w-48 md:h-48 border border-gray-200 rounded-lg bg-white"
                    onClick={() => openModal(license.pdf)}
                  >
                    <img
                      src={license.image}
                      alt={license.alt}
                      className="max-w-full max-h-full object-contain cursor-pointer"
                    />
                  </div>
                ))}
              </div>

              <button
                onClick={handleNext}
                className="absolute right-0 bg-white rounded-full shadow-lg border border-gray-200 p-3 hover:bg-gray-50 focus:outline-none z-10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-green-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>

            <div className="flex justify-center items-center space-x-3 mt-6">
              {licenses.map((_, index) => (
                <div
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`w-3 h-3 rounded-full cursor-pointer ${
                    index === currentIndex ? "bg-[#65A64E]" : "bg-gray-300"
                  }`}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-11/12 max-w-screen-lg p-6 rounded shadow-lg relative">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-300 hover:text-gray-500 text-4xl font-bold"
            >
              &times;
            </button>
            <iframe
              src={selectedPdf}
              title="Certificate"
              className="w-full h-[32rem]"
            ></iframe>
          </div>
        </div>
      )}
    </section>
  );
};

export default Licenses;
