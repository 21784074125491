import React from "react";
import AboutUsImage from "../../assets/About/nutrients.webp";
import DietryImage from "../../assets/About/dietry.jpg";
import SportsImage from "../../assets/About/Sports.jpg";
import CustomerisedImage from "../../assets/About/Customerised.jpg";
import HerbalImage from "../../assets/About/hervalextracts.webp";
import FunctionalImage from "../../assets/About/FunctionalFood.jpg";

const AboutUs = () => {
  return (
    <div className="bg-gray-50 text-gray-800 py-12 px-6" id="about-us">
      <div className="mx-auto w-full max-w-screen-xl" style={{ maxWidth: "min(80%, 1200px)" }}>
        {/* Header Section */}
        <div className="text-center mb-16">
          <h1 className="text-5xl font-bold text-[#65A64E] mb-6">About Us</h1>
          <p className="text-xl">
            Welcome to Pureform Labs, where science meets nature to deliver
            health and wellness products of the highest quality.
          </p>
        </div>

        {/* Mission Section */}
        <section className="mb-16">
          <div className="flex flex-col md:flex-row items-center md:items-start">
            <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8 text-center md:text-left">
              <h2 className="text-3xl font-semibold text-[#65A64E] mb-6">
                Our Mission
              </h2>
              <p className="text-lg leading-relaxed">
                At Pureform Labs, we are driven by a commitment to improve
                health and wellness globally. We blend cutting-edge research
                with the finest natural ingredients to produce nutraceuticals
                that enhance health, support wellness, and empower individuals
                to lead healthier lives.
              </p>
            </div>
            <div className="md:w-1/2">
              <img
                src={AboutUsImage}
                alt="Our Mission"
                className="rounded shadow-md w-full"
              />
            </div>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold text-center text-[#65A64E] mb-8">
            Why Choose Us?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 text-center">
            <div className="p-6 bg-white shadow-md rounded-md">
              <h3 className="text-2xl font-bold text-[#65A64E] mb-4">
                Quality Assurance
              </h3>
              <p className="text-lg">
                Our facility is GMP-certified and adheres to stringent industry
                standards. Rigorous testing ensures every product meets the
                highest quality and safety standards.
              </p>
            </div>
            <div className="p-6 bg-white shadow-md rounded-md">
              <h3 className="text-2xl font-bold text-[#65A64E] mb-4">
                Innovation and Research
              </h3>
              <p className="text-lg">
                Our R&D team develops innovative products that meet evolving
                health needs, keeping up with the latest in health science.
              </p>
            </div>
            <div className="p-6 bg-white shadow-md rounded-md">
              <h3 className="text-2xl font-bold text-[#65A64E] mb-4">
                State-of-the-Art Manufacturing
              </h3>
              <p className="text-lg">
                Advanced technology enables us to produce a broad spectrum of
                nutraceutical products efficiently.
              </p>
            </div>
            <div className="p-6 bg-white shadow-md rounded-md">
              <h3 className="text-2xl font-bold text-[#65A64E] mb-4">
                Commitment to Sustainability
              </h3>
              <p className="text-lg">
                We responsibly source ingredients and use eco-friendly practices
                to support a healthier planet.
              </p>
            </div>
          </div>
        </section>

        {/* Products Section */}
        <section className="mb-12" id="products">
          <h2 className="text-2xl font-semibold text-[#65A64E] mb-6">
            Our Products
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Dietary Supplements */}
            <div className="p-6 bg-white shadow-md rounded">
              <img
                src={DietryImage}
                alt="Dietary Supplements"
                className="w-full h-40 object-cover rounded mb-4"
              />
              <h3 className="text-lg font-bold text-[#65A64E]">
                Dietary Supplements
              </h3>
              <p>
                Vitamins, minerals, amino acids, and more to support overall
                health.
              </p>
            </div>

            {/* Herbal Extracts */}
            <div className="p-6 bg-white shadow-md rounded">
              <img
                src={HerbalImage}
                alt="Herbal Extracts"
                className="w-full h-40 object-cover rounded mb-4"
              />
              <h3 className="text-lg font-bold text-[#65A64E]">
                Herbal Extracts
              </h3>
              <p>Pure, high-quality extracts from renowned medicinal plants.</p>
            </div>

            {/* Sports Nutrition */}
            <div className="p-6 bg-white shadow-md rounded">
              <img
                src={SportsImage}
                alt="Sports Nutrition"
                className="w-full h-40 object-cover rounded mb-4"
              />
              <h3 className="text-lg font-bold text-[#65A64E]">
                Sports Nutrition
              </h3>
              <p>
                Formulated products to enhance performance, recovery, and
                endurance.
              </p>
            </div>

            {/* Functional Foods */}
            <div className="p-6 bg-white shadow-md rounded">
              <img
                src={FunctionalImage}
                alt="Functional Foods"
                className="w-full h-40 object-cover rounded mb-4"
              />
              <h3 className="text-lg font-bold text-[#65A64E]">
                Functional Foods
              </h3>
              <p>Nutrient-rich foods to support various health goals.</p>
            </div>

            {/* Customized Formulations */}
            <div className="p-6 bg-white shadow-md rounded">
              <img
                src={CustomerisedImage}
                alt="Customized Formulations"
                className="w-full h-40 object-cover rounded mb-4"
              />
              <h3 className="text-lg font-bold text-[#65A64E]">
                Customized Formulations
              </h3>
              <p>
                Tailored solutions for private labels and personalized wellness
                needs.
              </p>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <div className="text-center">
          <button
            className="bg-[#65A64E] text-white py-3 px-8 rounded-md shadow-md hover:bg-[#4f8a3d] transition duration-300"
            onClick={() => (window.location.href = "/products")}
          >
           All Products
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
