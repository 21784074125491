import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./Components/AboutUs/Aboutus";
import NavBar from "./Components/Header/NavBar";
import Footer from "./Components/Footer/Footer";
import ProductSection from "./Components/ProductSection/ProductSection";
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        {/* Navbar at the top */}
        <NavBar />

        {/* Main content */}
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path='/products' element={<ProductSection/>}  />
            {/* <Route path="/contact-us" element={<ContactUs />} /> */}
          </Routes>
        </div>

        {/* Footer at the bottom */}

        <Footer />
      </div>
      <Analytics />
      <SpeedInsights />
    </Router>
  );
}

export default App;
