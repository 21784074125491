import React, { useState } from "react";
import productList from "../../constants/ProductList";

const ProductSection = () => {
  const [filters, setFilters] = useState({
    category: "",
    subCategory: "",
    segment: "",
    dosage: "",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value.toLowerCase() }));
  };

  const filteredProducts = productList.filter(
    (product) =>
      (!filters.category ||
        product.mainCategory.toLowerCase().includes(filters.category)) &&
      (!filters.subCategory ||
        product.subCategory.toLowerCase().includes(filters.subCategory)) &&
      (!filters.segment ||
        product.segment.toLowerCase().includes(filters.segment)) &&
      (!filters.dosage ||
        product.dosageForms.toLowerCase().includes(filters.dosage))
  );

  return (
    <section className="bg-white py-16 px-6 md:px-16" id="products">
      <div className="mx-auto w-full max-w-screen-xl">
        {/* Title */}
        <h1 className="text-3xl md:text-4xl font-bold text-[#65A64E] text-center mb-8">
          Our Products
        </h1>

        {/* Filters */}
        <div className="flex flex-wrap gap-6 mb-8 justify-center">
          <input
            type="text"
            name="category"
            value={filters.category}
            onChange={handleFilterChange}
            placeholder="Search by Category"
            className="border border-gray-300 p-3 rounded-md w-full md:w-[calc(25%-20px)]"
          />
          <input
            type="text"
            name="subCategory"
            value={filters.subCategory}
            onChange={handleFilterChange}
            placeholder="Search by Sub Category"
            className="border border-gray-300 p-3 rounded-md w-full md:w-[calc(25%-20px)]"
          />
          <select
            name="segment"
            value={filters.segment}
            onChange={handleFilterChange}
            className="border border-gray-300 p-3 rounded-md w-full md:w-[calc(25%-20px)]"
          >
            <option value="">Select Segment</option>
            <option value="Nutraceuticals">Nutraceuticals</option>
          </select>
          <select
            name="dosage"
            value={filters.dosage}
            onChange={handleFilterChange}
            className="border border-gray-300 p-3 rounded-md w-full md:w-[calc(25%-20px)]"
          >
            <option value="">Select Dosage</option>
            <option value="Syrup">Syrup</option>
            <option value="Capsule">Capsules</option>
            <option value="Tablet">Tablet</option>
            <option value="Jar">Jar</option>
          </select>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse bg-white shadow-lg rounded-md overflow-hidden">
            <thead className="bg-[#65A64E] text-white">
              <tr>
                <th className="border border-gray-300 p-4 text-left text-sm sm:text-base">Main Category</th>
                <th className="border border-gray-300 p-4 text-left text-sm sm:text-base">Sub Category</th>
                <th className="border border-gray-300 p-4 text-left text-sm sm:text-base">Segment</th>
                <th className="border border-gray-300 p-4 text-left text-sm sm:text-base">Dosage Forms</th>
                <th className="border border-gray-300 p-4 text-left text-sm sm:text-base">Composition</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.length > 0 ? (
                filteredProducts.map((product, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="border border-gray-300 p-4 text-sm sm:text-base">
                      {product.mainCategory}
                    </td>
                    <td className="border border-gray-300 p-4 text-sm sm:text-base">
                      {product.subCategory}
                    </td>
                    <td className="border border-gray-300 p-4 text-sm sm:text-base">
                      {product.segment}
                    </td>
                    <td className="border border-gray-300 p-4 text-sm sm:text-base">
                      {product.dosageForms}
                    </td>
                    <td className="border border-gray-300 p-4 text-sm sm:text-base">
                      {product.composition}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="5"
                    className="border border-gray-300 p-4 text-center text-gray-500 text-sm sm:text-base"
                  >
                    No products found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default ProductSection;
