import React from "react";

const ManufacturingFacility = () => {
  const items = [
    "Rapid Mixer Granulator",
    "Fluid Bed Dryer",
    "Octagonal Blender",
    "Paste Kettle",
    "Double Cone Blender",
    "Ribbon Blender",
    "Vibro Shifters",
    "Multi mill",
    "Capsule Auto Loader and Filling Machine",
    "Blister Packing Machine",
    "Automatic and Manual Jar Filling Line",
    "Automatic Sachet Machine",
    "Shrink Tunnel",
    "Dispensing Booth",
    "Pass Box",
    "DHU",
    "AHUs",
    "Airlocks",
    "Epoxy Coated Floors",
    "Clean Rooms",
    "DT Tester",
    "Hardness Tester",
    "Dissolution Apparatus",
    "Kjeldahl's Apparatus",
    "Vacuum Oven",
    "Leak Test Apparatus",
    "Stability Testing Chambers",
    "UV Spectrophotometer",
  ];

  return (
    <div className="bg-gray-50 text-gray-800 py-12 px-6" id="manufacturing-facility">
      <div
        className="mx-auto w-full max-w-screen-xl"
        style={{ maxWidth: "min(90%, 1200px)" }} // Slightly narrower for better mobile spacing
      >
        {/* Header Section */}
        <div className="text-center mb-12 sm:mb-16">
          <h1 className="text-3xl sm:text-5xl font-bold text-[#65A64E] mb-4 sm:mb-6">
            Manufacturing Facility
          </h1>
          <p className="text-base sm:text-xl">
            Equipped with state-of-the-art technology, our facility ensures precision, quality, and efficiency in every product we manufacture.
          </p>
        </div>

        {/* Facility Items */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {items.map((item, index) => (
            <div
              key={index}
              className="p-4 sm:p-6 bg-white shadow-md rounded-md flex items-center gap-4"
            >
              <div className="w-10 h-10 sm:w-12 sm:h-12 bg-[#65A64E] text-white rounded-full flex items-center justify-center text-lg sm:text-xl font-bold">
                {index + 1}
              </div>
              <p className="text-sm sm:text-lg font-medium text-gray-700">{item}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManufacturingFacility;
