import React from 'react';
import Dependeble from "../../assets/serve/dependable.png"
import Fast from "../../assets/serve/fast.png"
import Excellence from "../../assets/serve/excellence.png"
import Helpfull from "../../assets/serve/helping-hand.png"
import Practical from "../../assets/serve/learning.png"
import Quick from "../../assets/serve/quick-response.png"
// Icon placeholders (replace with your actual icons)
const icons = {
  dependable: Dependeble, // Replace with the actual icon for "Dependable"
  fast: Fast, // Replace with the actual icon for "Fast"
  excellence:Excellence, // Replace with the actual icon for "Excellence"
  helpful: Helpfull, // Replace with the actual icon for "Helpful"
  practical: Practical, // Replace with the actual icon for "Practical"
  quick: Quick, // Replace with the actual icon for "Quick"
};

const WhatWeServe = () => {
  const services = [
    {
      title: "DEPENDABLE",
      description: "Consistently good in quality or performance; able to be trusted.",
      icon: icons.dependable,
      bgColor: "bg-white",
    },
    {
      title: "FAST",
      description: "Succeed in obtaining (something), especially with difficulty.",
      icon: icons.fast,
      bgColor: "bg-[#65A64E]", // Green background
    },
    {
      title: "EXCELLENCE",
      description: "Improving quality of quality products.",
      icon: icons.excellence,
      bgColor: "bg-white",
    },
    {
      title: "HELPFUL",
      description: "Always creating something that is useful.",
      icon: icons.helpful,
      bgColor: "bg-[#65A64E]", // Green background
    },
    {
      title: "PRACTICAL",
      description: "In accordance with wisdom to be of benefit.",
      icon: icons.practical,
      bgColor: "bg-white",
    },
    {
      title: "QUICK",
      description: "Able to move quickly with witty decisions.",
      icon: icons.quick,
      bgColor: "bg-[#65A64E]", // Green background
    },
  ];

  return (
    <section className="py-12 px-6 md:px-16 bg-gray-100">
      <div className="text-center mb-8">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800">
          What We Serve
        </h2>
        <div className="w-16 h-1 bg-[#65A64E] mx-auto mt-2"></div> {/* Divider color updated */}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-0"> {/* No gap between the cards */}
        {services.map((service, index) => (
          <div
            key={index}
            className={`p-6 ${service.bgColor} flex flex-col items-center border ${service.bgColor === 'bg-[#65A64E]' ? 'border-[#65A64E]' : ''}`}
            style={{ borderRadius: '0' }} // Removed rounded corners
          >
            <img
              src={service.icon}
              alt={service.title}
              className="w-16 h-16 mb-4"
            />
            <h3
              className={`text-lg font-bold ${service.bgColor === 'bg-[#65A64E]' ? 'text-white' : 'text-[#65A64E]'} mb-2`}
            >
              {service.title}
            </h3>
            <p
              className={`text-center ${service.bgColor === 'bg-[#65A64E]' ? 'text-white' : 'text-[#65A64E]'} `}
            >
              {service.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhatWeServe;
