import React, { useState, useEffect } from "react";

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      text: "Growequal's products have been a game changer for our business. The quality and performance are top-notch, and we've seen significant improvements since using them. Their customer support is also incredibly responsive and helpful. Highly recommend their services!",
      name: "Merin Thomas",
      role: "Happy Customer",
      image: "https://via.placeholder.com/100",
    },
    {
      id: 2,
      text: "We have been using Growequal's products for several months now, and we are thrilled with the results. The products are high-quality, reliable, and have helped streamline our operations. Customer service is excellent, always quick to assist with any questions.",
      name: "Oscar",
      role: "Happy Customer",
      image: "https://via.placeholder.com/100",
    },
    {
      id: 3,
      text: "Excellent products and service. Growequal has consistently provided high-quality solutions that meet our needs. The team is always professional and willing to go the extra mile to ensure satisfaction. We're very happy with the overall experience.",
      name: "Sophia",
      role: "Happy Customer",
      image: "https://via.placeholder.com/100",
    },
    {
      id: 4,
      text: "We’ve been working with Growequal for over a year now, and we couldn’t be happier. Their products are reliable, affordable, and the support team is always available to address any issues. Truly a great company to work with!",
      name: "Daniel",
      role: "Happy Customer",
      image: "https://via.placeholder.com/100",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(1);

  useEffect(() => {
    const updateCardsToShow = () => {
      if (window.innerWidth >= 1024) {
        setCardsToShow(3); // Show 3 cards on large screens
      } else if (window.innerWidth >= 768) {
        setCardsToShow(2); // Show 2 cards on medium screens
      } else {
        setCardsToShow(1); // Show 1 card on small screens
      }
    };

    updateCardsToShow(); // Initial setup
    window.addEventListener("resize", updateCardsToShow); // Adjust on resize
    return () => window.removeEventListener("resize", updateCardsToShow); // Cleanup
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prev) =>
      prev === 0 ? testimonials.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prev) =>
      (prev + cardsToShow) % testimonials.length
    );
  };

  const getVisibleTestimonials = () => {
    const visibleTestimonials = [];
    for (let i = 0; i < cardsToShow; i++) {
      visibleTestimonials.push(
        testimonials[(currentIndex + i) % testimonials.length]
      );
    }
    return visibleTestimonials;
  };

  return (
    <section
      id="testimonials"
      className="bg-gradient-to-br from-green-50 to-white py-16 px-6"
    >
      <div className="container mx-auto max-w-screen-lg w-4/5">
        {/* Heading */}
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-extrabold text-[#65A64E]">
            Happy Customer Testimonials
          </h2>
          <p className="text-gray-600 text-sm md:text-base mt-2">
            What our customers are saying
          </p>
        </div>

        {/* Testimonials Slider */}
        <div className="relative flex flex-col items-center">
          <div className={`grid gap-6 ${cardsToShow === 1 ? "w-full" : "md:grid-cols-2 lg:grid-cols-3"}`}>
            {getVisibleTestimonials().map((item) => (
              <div
                key={item.id}
                className="bg-white rounded-lg shadow-lg p-6 flex flex-col space-y-4"
              >
                <div className="text-green-400 text-4xl font-bold">
                  &ldquo;
                </div>
                <p className="text-gray-700 italic text-lg">{item.text}</p>
                <div className="flex items-center mt-auto">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-12 h-12 rounded-full border-2 border-green-400"
                  />
                  <div className="ml-4">
                    <h3 className="text-[#65A64E] font-semibold">{item.name}</h3>
                    <p className="text-gray-500 text-sm">{item.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Navigation */}
          <div className="mt-8 flex justify-center md:justify-between w-full px-6 md:px-0">
            <button
              onClick={handlePrev}
              className="bg-[#65A64E] text-white rounded-full px-4 py-2 shadow hover:bg-green-700 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <button
              onClick={handleNext}
              className="bg-[#65A64E] text-white rounded-full px-4 py-2 shadow hover:bg-green-700 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
